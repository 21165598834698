<template lang="pug">
  img.pic-url(:src="picUrl")
</template>

<script>
export default {
  data() {
    return {
      picUrl: ''
    }
  },
  created() {
    const {picUrl} = this.$route.query
    this.picUrl = picUrl
  },
  methods: {}
}
</script>

<style lang="scss">
.pic-url {
  margin: 0 auto;
}
</style>
